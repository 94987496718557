import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

type BrandsResponse = { marques: string[][] };
const fetchBrands = (): Promise<AxiosResponse<BrandsResponse>> =>
  axios.get("https://vefaa-back-end.fly.dev/algo/brands");
export const useBrandsQuery = () =>
  useQuery({
    queryKey: ["BackEnd", "Brands"],
    queryFn: fetchBrands,
    select: (response) => response.data.marques.map((brand) => brand[0]),
  });

export type ResultResponse = {
  economie_par_mois: number;
  temps_remboursement_mois: number;
  temps_trajet_velo: number;
  pollution_par_mois: number;
  aide_totale: number;
  aide_sc: number;
  aide_mobilite_durable: number;
  aide_fonctionnaire: number;
  prix_essence: number;
  consommation: number;
  pollution: number;
  distance: number;
  temps: number;
  url: string;
};
const fetchResult = (values: string): Promise<AxiosResponse<ResultResponse>> =>
  axios.get(`https://vefaa-back-end.fly.dev/algo/result?values=${values}`);
export const useResultQuery = ({ values }: { values: string }) =>
  useQuery({
    queryKey: ["BackEnd", "Result", values],
    queryFn: () => fetchResult(values),
    select: (response) => response.data,
  });
