import { ReactNode } from "react";
import { Link as RouterLink } from "react-static-url-router";
import "./Link.scss";

type LinkProps =
  | {
      to: string;
      children: ReactNode;
      color?: "primary" | "muted";
      disabled?: boolean;
      onClick?: null;
    }
  | {
      to?: null;
      children: ReactNode;
      color?: "primary" | "muted";
      disabled?: boolean;
      onClick: () => void;
    };

const Link = ({
  to,
  children,
  color = "primary",
  disabled,
  onClick,
}: LinkProps) => {
  const colorClass = [
    `Link--color-${color}`,
    disabled ? "Link--color--disabled" : undefined,
  ]
    .filter((el) => el)
    .join(" ");
  const classNames = ["Link", colorClass].filter((el) => el).join(" ");

  if (disabled) {
    return <span className={classNames}>{children}</span>;
  }

  if (typeof to === "string") {
    return (
      <RouterLink to={to} className={classNames}>
        {children}
      </RouterLink>
    );
  } else {
    return (
      <RouterLink onClick={onClick} className={classNames}>
        {children}
      </RouterLink>
    );
  }
};

export default Link;
