import { ReactNode } from "react";
import Box from "../../../../components/layout/Box";
import Link from "../../../../components/navigation/Link";
import { ChevronLeft, ChevronRight } from "react-feather";

type SimulatorLayoutBaseProps = {
  children: ReactNode;
  isCtaDisabled: boolean;
  onCtaClick: () => void;
  isLastStep: boolean;
};
type SimulatorLayoutProps =
  | (SimulatorLayoutBaseProps & {
      isFirstStep: true;
      onPreviousClick?: undefined;
    })
  | (SimulatorLayoutBaseProps & {
      isFirstStep: false;
      onPreviousClick: () => void;
    });
const SimulatorLayout = ({
  children,
  isCtaDisabled,
  onCtaClick,
  isLastStep,
  isFirstStep,
  onPreviousClick,
}: SimulatorLayoutProps) => {
  const mainLinkContent = isLastStep ? (
    "Terminer"
  ) : (
    <Box flex justifyContent="center" alignItems="center">
      Continuer <ChevronRight />
    </Box>
  );
  return (
    <Box flexRatio={1} all="m" flex col>
      <Box col flexRatio={1} gap="m">
        {children}
      </Box>
      <Box flex justifyContent="space-between" reverse>
        <Link disabled={isCtaDisabled} onClick={onCtaClick}>
          {mainLinkContent}
        </Link>
        {!isFirstStep && (
          <Link onClick={onPreviousClick}>
            <Box flex justifyContent="center" alignItems="center">
              <ChevronLeft /> Retour
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default SimulatorLayout;
