import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type QueryKey = [
  base_key: string,
  session_token: string,
  variable_key: string,
  proximity?: [longitude: number, latitude: number],
];

export interface MapBoxSuggestionsResponse {
  suggestions: MapBoxSuggestionsResponseSuggestions[];
  attribution: string;
}
export interface MapBoxSuggestionsResponseSuggestions {
  name: string;
  mapbox_id: string;
  feature_type: string;
  address: string;
  full_address: string;
  place_formatted: string;
  context: {
    country: {
      name: string;
      country_code: string;
      country_code_alpha_3: string;
    };
    postcode: {
      id: string;
      name: string;
    };
    place: {
      id: string;
      name: string;
    };
    locality: {
      id: string;
      name: string;
    };
    neighborhood: {
      id: string;
      name: string;
    };
  };
  language: string;
  maki: string;
  poi_category: string[];
  poi_category_ids: string[];
  external_ids: unknown;
  metadata: unknown;
}

export const fetchMapBoxSearch = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<MapBoxSuggestionsResponse> =>
  axios
    .get(
      `https://api.mapbox.com/search/searchbox/v1/suggest?q=${
        queryKey[2]
      }&bbox=-54.5247541978,2.05338918702,9.56001631027,51.1485061713&types=address&country=fr&access_token=${
        process.env.REACT_APP_MAPBOX_API_KEY
      }&session_token=${queryKey[1]}${
        queryKey[3] ? "&proximity=" + queryKey[3] : ""
      }`,
    )
    .then((response) => response.data);

export const useMapBoxSearchQuery = ({
  searchedAddress = "",
  sessionToken,
}: {
  searchedAddress: string;
  sessionToken: string;
}) =>
  useQuery({
    queryKey: ["MapBoxSearch", sessionToken, searchedAddress],
    queryFn: fetchMapBoxSearch,
    enabled: searchedAddress.length > 3,
  });

export interface MapBoxRetrieveResponse {
  features: MapBoxRetrieveResponseFeature[];
  attribution: string;
  type: unknown;
}
export interface MapBoxRetrieveResponseFeature {
  type: "Feature";
  geometry: {
    coordinates: [latitude: number, longitude: number];
    type: "Point";
  };
  properties: {
    name: string;
    mapbox_id: string;
    feature_type: "address";
    address: string;
    full_address: string;
    place_formatted: string;
    context: {
      country: {
        id: string;
        name: string;
        country_code: string;
        country_code_alpha_3: string;
      };
      region: {
        id: string;
        name: string;
        region_code: string;
        region_code_full: string;
      };
      postcode: {
        id: string;
        name: string;
      };
      place: {
        id: string;
        name: string;
      };
      address: {
        id: string;
        name: string;
        address_number: string;
        street_name: string;
      };
      street: {
        id: string;
        name: string;
      };
    };
    coordinates: {
      latitude: number;
      longitude: number;
      accuracy: string;
      routable_points: [
        {
          name: string;
          latitude: number;
          longitude: number;
        },
      ];
    };
    language: string;
    maki: string;
    external_ids: unknown;
    metadata: unknown;
  };
}

export const fetchMapBoxRetrieve = ({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<MapBoxRetrieveResponse> =>
  axios
    .get(
      `https://api.mapbox.com/search/searchbox/v1/retrieve/${queryKey[2]}?&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}&session_token=${queryKey[1]}`,
    )
    .then((response) => response.data);
