import "./App.scss";
import Landing from "./pages/Landing";
import Simulator from "./pages/Form";
import { Result } from "./pages/Result";
import "./App.scss";
import { Route, Router, Routes } from "react-static-url-router";

function App() {
  return (
    <main className="App">
      <Router queryKey="vefaa_simulator">
        <Routes>
          <Route root path="/landing" component={Landing} />
          <Route path="/simulator" component={Simulator} />
          <Route path="/result" component={Result} />
        </Routes>
      </Router>
    </main>
  );
}

export { App };
