import Box from "../../components/layout/Box";
import Heading from "../../components/typography/Heading";
import { Text } from "../../components/typography/Text";

const CONSUMPTION_EQUIVALENT_DATA = {
  jeans: {
    value: 0.04,
    label: "Jeans",
    emoji:
      "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f456.svg",
    alt: "A jean emoji",
  },
  tshirt: {
    value: 0.16,
    label: "T-shirt",
    emoji:
      "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f455.svg",
    alt: "A t-shirt emoji",
  },
  smartphone: {
    value: 0.03,
    label: "Smartphone",
    emoji:
      "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f1.svg",
    alt: "A smartphone emoji",
  },
} as const;

const Equivalents = ({ polution }: { polution: number }) => {
  return (
    <Box flex gap="m">
      {Object.entries(CONSUMPTION_EQUIVALENT_DATA).map(
        ([, { value, label, emoji, alt }]) => (
          <Box flexRatio={1} col border="primary" rounded>
            <Heading align="center" size="h3">
              {(value * polution).toFixed(2)}
            </Heading>
            <img height="30px" alt={alt} src={emoji}></img>
            <Text align="center">{label}</Text>
          </Box>
        ),
      )}
    </Box>
  );
};

export { Equivalents };
