import { ChangeEvent, ReactNode, createContext, useContext } from "react";
import Box from "../layout/Box";
import { useFormikContext } from "formik";
import { Slider } from "@mui/material";
import { Text } from "../typography/Text";
import styled from "styled-components";

const SliderInputContext = createContext<null | string>(null);

const Root = ({
  fieldName,
  children,
}: {
  fieldName: string;
  children: ReactNode;
}) => {
  return (
    <Box col>
      <SliderInputContext.Provider value={fieldName}>
        {children}
      </SliderInputContext.Provider>
    </Box>
  );
};

const Label = ({ children }: { children: ReactNode }) => {
  const fieldName = useContext(SliderInputContext);
  if (fieldName === null) {
    throw new Error(
      "the SliderInput.Label component must be used inside a SliderInput.Root component",
    );
  }
  return <Text>{children}</Text>;
};

const StyledNumberInputLabel = styled.label`
  display: flex;
  position: relative;
  padding: 4px;
  gap: 4px;
  width: fit-content;
  margin-inline-start: auto;

  & > input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    color: var(--color-text-main);
    flex-grow: 1;
    text-align: end;
    border: none;
    padding-block: 1px;
    padding-inline: 2px;

    &:focus {
      outline: none;

      & ~ .wrapper {
        border: solid var(--color-background-primary);
      }
    }
  }

  & > .wrapper {
    border: solid var(--color-background-primary-light);
    border-radius: var(--radii-m);
    height: calc(1rem + 12px);
    width: 100%;
    position: absolute;
    top: -2px;
    left: -2px;
  }

  & > span {
    color: var(--color-text-main);
    font-size: var(--font-size-xs);
    margin-block: auto;
  }
`;

const Input = ({
  value,
  unit,
  min,
  max,
  step,
}: {
  unit?: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
}) => {
  const fieldName = useContext(SliderInputContext);
  const { setFieldValue } = useFormikContext();
  if (fieldName === null) {
    throw new Error(
      "the SliderInput.Input component must be used inside a SliderInput.Root component",
    );
  }
  const setValueFromSlider = (event: Event): void => {
    const target = event.target as HTMLTextAreaElement;
    setFieldValue(fieldName, target.value);
  };
  const setValueFromInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target;
    setFieldValue(fieldName, target.value);
  };

  return (
    <Box flex col gap="m">
      <StyledNumberInputLabel htmlFor={fieldName}>
        <input
          type="number"
          name={fieldName}
          id={fieldName}
          value={value}
          onChange={setValueFromInput}
        />
        <span>{unit}</span>
        <div className="wrapper"></div>
      </StyledNumberInputLabel>
      <Box col flexRatio={1} justifyContent="center">
        <Slider
          min={min}
          max={max}
          step={step}
          aria-label="Volume"
          value={value}
          onChange={setValueFromSlider}
          sx={{ color: "var(--color-background-primary)" }}
        />
      </Box>
    </Box>
  );
};

const SliderInput = {
  Root,
  Label,
  Input,
};

export default SliderInput;
