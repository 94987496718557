import { MouseEventHandler, ReactNode } from "react";
import "./Button.scss";
import Box from "../layout/Box";

type ButtonProps = {
  color?: "primary" | "secondary" | "muted";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: ReactNode;
};
const Button = ({
  disabled,
  color = "primary",
  onClick,
  children,
}: ButtonProps) => {
  const colorClass = `Button--color-${color}`;
  const colorDisabledClass = disabled ? `${colorClass}--disabled` : undefined;
  const buttonClasses = ["Button", colorClass, colorDisabledClass]
    .filter((className) => className)
    .join(" ");
  const Container = disabled ? Box : "button";
  return (
    <Container onClick={onClick} className={buttonClasses}>
      {children}
    </Container>
  );
};

export default Button;
