import { ReactNode } from "react";
import Box, { BoxProps } from "../layout/Box";

type InputGroupProps = {
  children: ReactNode;
} & BoxProps;
const InputGroup = ({ children, ...props }: InputGroupProps) => {
  return <Box {...{ col: true, gap: "m", ...props }}>{children}</Box>;
};

export default InputGroup;
