import electricBike from "./ElectricBike.png";
import informations from "./Informations.jpg";
import savings from "./Savings.jpg";
import LogoGeoVelo from "./LogoGeoVelo.svg";
import LogoMesAidesVelo from "./LogoMesAidesVelo.png";
import ImpactCO2 from "./ImpactCO2.png";

export const images = {
  electricBike,
  informations,
  savings,
  LogoGeoVelo,
  LogoMesAidesVelo,
  ImpactCO2
};
