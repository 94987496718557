import { useFormikContext } from "formik";

import { AddressesStepContent } from "./AddressesStepContent";
import Box from "../../../components/layout/Box";
import { FormValues } from "../../Form";

import { VehicleStepContent } from "./VehicleStepContent";
import ProgressBar from "../../../components/molecules/ProgressBar";
import { SubventionStepContent } from "./SubventionStepContent";
import Illustration from "../../../images/Illustration.png";

export enum Steps {
  Addresses = "Votre adresse",
  Vehicle = "Votre véhicule",
  Subventions = "Forfait Mobilité Durable",
}

const Components = {
  Addresses: AddressesStepContent,
  Vehicle: VehicleStepContent,
  Subventions: SubventionStepContent,
} as const;

const FormContent = () => {
  const { values } = useFormikContext<FormValues>();

  const isAddressesStepCompleted =
    values.addresses.home.label.length > 0 &&
    values.addresses.work.label.length > 0 &&
    values.addresses.weekly_comutes > 0 &&
    values.addresses.submitted;

  const isVehicleTypeStepCompleted =
    isAddressesStepCompleted &&
    values.vehicle.type !== "unknown" &&
    values.vehicle.submitted;
  // const isVehicleTypeStepCompleted = true

  const findStep = (): keyof typeof Steps => {
    if (isVehicleTypeStepCompleted) {
      return "Subventions";
    }
    if (isAddressesStepCompleted) {
      return "Vehicle";
    }

    return "Addresses";
  };

  console.log({ findStep: findStep(), isVehicleTypeStepCompleted });
  const step = findStep();

  const StepComponent = Components[step];

  const steps = Object.entries(Steps).map(([, value]) => value);

  return (
    <>
      <Box flexRatio={1} flex col all="m">
        <ProgressBar steps={steps} activeStep={Steps[step]} />
        <Box flex col flexRatio={1} justifyContent="center" alignItems="center">
          <img src={Illustration} alt="Illustration" style={{ width: "80%" }} />
        </Box>
      </Box>
      <StepComponent />
    </>
  );
};

export { FormContent };
