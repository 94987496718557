import { Formik, Form as FormikForm } from "formik";
import { FormContent } from "./private/simulator/FormContent";
import Box from "../components/layout/Box";
import styled from "styled-components";

interface AddressValue {
  latitude: number;
  longitude: number;
  label: string;
  zipcode?: string;
  city?: string;
}

export enum Fuel {
  "SP95" = "SP95",
  "SP98" = "SP98",
  "Gazole" = "Gazole",
  "E10" = "E10",
  "Electrique" = "Electrique",
}

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export interface FormValues {
  addresses: {
    home: AddressValue;
    work: AddressValue;
    weekly_comutes: number;
    submitted: boolean;
  };
  vehicle:
    | {
        type: "car";
        brand: string;
        model: string;
        fuel: keyof typeof Fuel | "unknown";
        consumption: number;
        isRegistrationYearKnown: "NO" | "YES" | "unknown";
        registrationYear: number | "unknown";
        submitted: boolean;
      }
    | {
        type: "bike";
        fuel: "unknown";
        brand: "unknown";
        model: "unknown";
        registrationYear: "unknown";
        isRegistrationYearKnown: "unknown";
        consumption: number;
        submitted: boolean;
      }
    | {
        type: "unknown";
        fuel: "unknown";
        brand: "unknown";
        model: "unknown";
        registrationYear: "unknown";
        isRegistrationYearKnown: "unknown";
        consumption: 0;
        submitted: false;
      };
  subvention: {
    isOfficial: "YES" | "NO" | "unknown";
    isTeacher: "YES" | "NO" | "unknown";
    hasCompanySetUpSubvention: "YES" | "NO" | "unknown";
    companySubventionFormat: "Daily" | "Mileage" | "unknown";
    maxAmount: number;
    amount: number;
    submitted: boolean;
  };
}

const Form = () => {
  const initialValues: FormValues = {
    addresses: {
      home: {
        label: "",
        latitude: 0,
        longitude: 0,
      },
      work: {
        label: "",
        latitude: 0,
        longitude: 0,
      },
      weekly_comutes: 0,
      submitted: false,
    },
    vehicle: {
      fuel: "unknown",
      brand: "unknown",
      model: "unknown",
      registrationYear: "unknown",
      consumption: 0,
      type: "unknown",
      isRegistrationYearKnown: "unknown",
      submitted: false,
    },
    subvention: {
      isOfficial: "unknown",
      isTeacher: "unknown",
      hasCompanySetUpSubvention: "unknown",
      companySubventionFormat: "unknown",
      amount: 0,
      maxAmount: 0,
      submitted: false,
    },
  };


  return (
    <Box flex col flexRatio={1}>
      <Formik initialValues={initialValues} onSubmit={() =>{}}>
        <StyledForm>
          <FormContent />
        </StyledForm>
      </Formik>
    </Box>
  );
};

export default Form;
