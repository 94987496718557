import { Autocomplete, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  fetchMapBoxSearch,
  fetchMapBoxRetrieve,
} from "../../../../queries/integrations/mapbox";
import { useMemo, useRef, useState } from "react";
import { useFormikContext } from "formik";
import { FormValues } from "../../../Form";

interface Suggestion {
  label: string;
  id: string;
}

interface AutocompleteInputProps {
  input_name: "home" | "work";
  proximity?: [longitude: number, latitude: number];
}
const AutocompleteInput = ({
  input_name,
  proximity,
}: AutocompleteInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const sessionToken = useRef<string>(uuidv4());
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const updateAddresses = ({target: {value}}: React.ChangeEvent<HTMLInputElement>): void => {
    
    fetchMapBoxSearch({
      queryKey: ["MapBoxSearch", sessionToken.current, value, proximity],
    }).then(({ suggestions }) => {
      const mappedSuggestions = suggestions.map((suggestions) => {
        return {
          label: suggestions.full_address,
          id: suggestions.mapbox_id,
        };
      });
      setSuggestions(mappedSuggestions);
    });
  };

  const inputValue = useMemo(
    () => ({ label: values.addresses[input_name].label, id: "" }),
    [values],
  );

  const fetchPlaceAndUpdateValues = (
    _: unknown,
    optionData: Suggestion | null,
  ): void => {
    fetchMapBoxRetrieve({
      queryKey: ["MapBoxRetrieve", sessionToken.current, optionData?.id || ""],
    }).then((response) => {
      const retrieved_address = response.features[0];
      setFieldValue("addresses." + input_name, {
        latitude: retrieved_address.properties.coordinates.latitude,
        longitude: retrieved_address.properties.coordinates.longitude,
        city: retrieved_address.properties.context.place.name,
        zipcode: retrieved_address.properties.context.postcode.name,
        label: retrieved_address.properties.full_address,
      });
    });
  };

  return (
    <Autocomplete
      value={inputValue}
      id={"addresses-" + input_name}
      options={suggestions}
      renderInput={(params) => <TextField {...params} />}
      getOptionLabel={(option) => option.label}
      onInput={updateAddresses}
      onChange={fetchPlaceAndUpdateValues}
      filterOptions={(x) => x}
    />
  );
};

export { AutocompleteInput };
