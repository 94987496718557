import "./ProgressBar.scss";
import Heading from "../typography/Heading";

interface ProgressBarProps {
  steps: string[];
  activeStep: string;
}

const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
  const activeStepClassName = " ProgressBar__Step--active";
  const stepIndex = steps.indexOf(activeStep) + 1;
  return (
    <div>
      <Heading variant="primary" align="center" size="h3">
        Étape {stepIndex} : {activeStep}
      </Heading>
      <ol className="ProgressBar">
        {steps.map((step) => (
          <li
            className={`ProgressBar__Step${
              step === activeStep ? activeStepClassName : ""
            }`}
          >
            {step}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ProgressBar;
