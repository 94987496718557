import { Field } from "formik";
import { ReactNode, createContext, useContext } from "react";
import "./TypePicker.scss";
import Heading from "../typography/Heading";
import { Text } from "../typography/Text";
import Box from "../layout/Box";

const TypePickerContext = createContext<null | string>(null);

type TypePickerRootProps = {
  fieldName: string;
  children: ReactNode;
};
const Root = ({ fieldName, children }: TypePickerRootProps) => {
  return (
    <Box col>
      <TypePickerContext.Provider value={fieldName}>
        {children}
      </TypePickerContext.Provider>
    </Box>
  );
};

type TypePickerOptionProps = {
  value: string;
  children: ReactNode;
  as?: "div" | "span";
};
const Option = ({ value, children, as = "div" }: TypePickerOptionProps) => {
  const fieldName = useContext(TypePickerContext);
  if (fieldName === null) {
    throw new Error(
      "the TypePicker.Option component must be used inside a TypePicker.Root component",
    );
  }
  const Container = as;
  return (
    <label
      htmlFor={`${fieldName}-${value}`}
      key={value}
      className="TypePicker__Option"
    >
      <Field
        type="radio"
        id={`${fieldName}-${value}`}
        name={fieldName}
        value={value}
        className="TypePicker__Option__Field"
      />
      <Container className="TypePicker__Option__Clickable">
        {children}
      </Container>
    </label>
  );
};

type TypePickerLabelProps = {
  children: ReactNode;
  as: typeof Text | typeof Heading;
};
const Label = ({ children, as }: TypePickerLabelProps) => {
  const Container = as;
  return <Container className="TypePicker__Label">{children}</Container>;
};

type TypePickerOptionsGroupProps = {
  children: ReactNode;
  direction?: "row" | "column";
};
const OptionsGroup = ({
  children,
  direction = "column",
}: TypePickerOptionsGroupProps) => {
  return (
    <Box flex col={direction === "column"} gap="m">
      {children}
    </Box>
  );
};

const TypePicker = {
  Root,
  Option,
  Label,
  OptionsGroup,
};

export { TypePicker, TypePickerContext };
