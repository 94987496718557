import { useFormikContext } from "formik";
import Box from "../../../components/layout/Box";
import { AutocompleteInput } from "./private/AutocompleteInput";
import { FormValues } from "../../Form";
import { Text } from "../../../components/typography/Text";
import SimulatorLayout from "./private/SimulatorLayout";
import { TypePicker } from "../../../components/form/TypePicker";

const AddressesStepContent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const is_home_address_filed = Boolean(values.addresses.home.label.length > 0);
  const is_work_address_filed = Boolean(values.addresses.work.label.length > 0);
  const should_display_work_address_input: boolean = is_home_address_filed;
  const is_weekly_comutes_filed = Boolean(values.addresses.weekly_comutes > 0);
  const should_display_weekly_comutes_input: boolean = is_work_address_filed;

  const should_unlock_submit: boolean = is_weekly_comutes_filed;
  console.log({values})
  return (
    <SimulatorLayout
      isFirstStep
      isLastStep={false}
      isCtaDisabled={!should_unlock_submit}
      onCtaClick={() => setFieldValue("addresses.submitted", true)}
    >
      <Box col>
        <Text>🏡 Votre domicile</Text>
        <AutocompleteInput input_name="home" />
      </Box>
      <Box col hidden={!should_display_work_address_input}>
        <Text>💻 Votre travail</Text>
        <AutocompleteInput
          input_name="work"
          proximity={[
            values.addresses.home.longitude,
            values.addresses.home.latitude,
          ]}
        />
      </Box>
      <Box hidden={!should_display_weekly_comutes_input}>
        <TypePicker.Root fieldName="addresses[weekly_comutes]">
          <TypePicker.Label as={Text}>
            💻 Combien de fois par semaine seriez-vous prêt(e) à faire ce trajet
            en vélo ?
          </TypePicker.Label>
          <TypePicker.OptionsGroup direction="row">
            {["1", "2", "3", "4", "5", "6", "7"].map((value) => (
              <TypePicker.Option value={value}>{value}</TypePicker.Option>
            ))}
          </TypePicker.OptionsGroup>
        </TypePicker.Root>
      </Box>
    </SimulatorLayout>
  );
};

export { AddressesStepContent };
