import Box from "../../../components/layout/Box";
import { FormValues, Fuel } from "../../Form";
import { Text } from "../../../components/typography/Text";
import { TypePicker } from "../../../components/form/TypePicker";
import { useFormikContext } from "formik";
import { useBrandsQuery } from "../../../queries/integrations/back_end";
import {
  Autocomplete,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import InputGroup from "../../../components/form/InputGroup";
import SimulatorLayout from "./private/SimulatorLayout";
import SliderInput from "../../../components/form/SliderInput";

const CarComponent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { data: brands } = useBrandsQuery();
  const fuelTypes = Object.keys(Fuel);
  if (!brands) return null;
  const getYears: () => number[] = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    let startYear = 1970;
    while (startYear <= currentYear) {
      years.unshift(startYear++);
    }
    return years;
  };

  const years = getYears();

  const setRegistrationYear = (event: SelectChangeEvent): void => {
    const registrationYear = (event.target as HTMLInputElement).value;
    setFieldValue("vehicle[registrationYear]", parseInt(registrationYear));
  };

  return (
    <Box col gap="m">
      <InputGroup gap="no">
        <Text>Marque</Text>
        <Autocomplete
          id={"brand"}
          options={brands}
          onInputChange={(event, selectBrand) =>
            setFieldValue("vehicle[brand]", selectBrand)
          }
          renderInput={(params) => (
            <TextField {...params} name="vehicle[brand]" />
          )}
        />
      </InputGroup>
      <InputGroup gap="no">
        <Text>Modèle</Text>
        <TextField
          name="vehicle[model]"
          type="text"
          onChange={(event) =>
            setFieldValue("vehicle[model]", event.target.value)
          }
        />
      </InputGroup>
      <TypePicker.Root fieldName="vehicle[fuel]">
        <TypePicker.Label as={Text}>Carburant utilisé</TypePicker.Label>
        <TypePicker.OptionsGroup direction="row">
          {fuelTypes.map((fuelType) => {
            return (
              <TypePicker.Option key={fuelType} value={fuelType}>
                {fuelType}
              </TypePicker.Option>
            );
          })}
        </TypePicker.OptionsGroup>
      </TypePicker.Root>
      <TypePicker.Root fieldName="vehicle[isRegistrationYearKnown]">
        <TypePicker.Label as={Text}>
          Connaissez-vous l’année de sa mise en circulation ?
        </TypePicker.Label>
        <TypePicker.OptionsGroup direction="row">
          <TypePicker.Option value="YES">Oui</TypePicker.Option>
          <TypePicker.Option value="NO">Non</TypePicker.Option>
        </TypePicker.OptionsGroup>
      </TypePicker.Root>
      <InputGroup
        hidden={values.vehicle.isRegistrationYearKnown !== "YES"}
        gap="no"
      >
        <Text>Année de mise en circulation</Text>
        <Select
          id="vehicle[registrationYear]"
          value={
            values.vehicle.registrationYear
              ? `${values.vehicle.registrationYear}`
              : ""
          }
          onChange={setRegistrationYear}
        >
          {years.map((year) => (
            <MenuItem value={year}>{year}</MenuItem>
          ))}
        </Select>
      </InputGroup>
    </Box>
  );
};

const BaseComponent = () => {
  const { values } = useFormikContext<FormValues>();
  const shouldDisplayConsumptionInput = values.vehicle.type === "bike";
  return (
    <Box col gap="m">
      <TypePicker.Root fieldName="vehicle[type]">
        <TypePicker.Label as={Text}>Quel véhicule avez vous ?</TypePicker.Label>
        <TypePicker.OptionsGroup direction="row">
          <TypePicker.Option value="car">🚗 Voiture</TypePicker.Option>
          <TypePicker.Option value="bike">🏍️ Moto</TypePicker.Option>
        </TypePicker.OptionsGroup>
      </TypePicker.Root>
      <Box hidden={!shouldDisplayConsumptionInput} col>
        <SliderInput.Root fieldName="vehicle[consumption]">
          <SliderInput.Label>
            Quelle est la consommation (L/100km) indiquée par le constructeur de
            votre vehicule ?
          </SliderInput.Label>
          <SliderInput.Input
            max={20}
            step={0.5}
            value={values.vehicle.consumption}
            unit="L/100km"
          />
        </SliderInput.Root>
      </Box>
    </Box>
  );
};

const VehicleStepContent = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  console.log({ fuel: values.vehicle.fuel });
  const shouldDisableLink = (): boolean => {
    if (values.vehicle.type === "bike" && values.vehicle.consumption > 0)
      return false;
    if (
      values.vehicle.type === "car" &&
      values.vehicle.brand !== "unknown" &&
      values.vehicle.model !== "unknown" &&
      values.vehicle.fuel !== "unknown" &&
      (values.vehicle.isRegistrationYearKnown === "NO" ||
        (values.vehicle.isRegistrationYearKnown === "YES" &&
          values.vehicle.registrationYear !== "unknown"))
    )
      return false;
    return true;
  };
  const Component =
    values.vehicle.type === "car" ? CarComponent : BaseComponent;

  return (
    <SimulatorLayout
      isFirstStep={false}
      onPreviousClick={() => setFieldValue("addresses.submitted", false)}
      isLastStep={false}
      isCtaDisabled={shouldDisableLink()}
      onCtaClick={() => setFieldValue("vehicle.submitted", true)}
    >
      <Component />
    </SimulatorLayout>
  );
};

export { VehicleStepContent };
