import { ReactNode } from "react";
import Box from "../components/layout/Box";
import Heading from "../components/typography/Heading";
import { Text } from "../components/typography/Text";
import { images } from "./private/images/index";
import Image from "../components/primitives/Image";
import Button from "../components/navigation/Button";
import { useNavigate } from "react-static-url-router";

type Illustration = { image: string; text: string; alt: string };
const IllustrationsData: Illustration[] = [
  {
    image: images.electricBike,
    text: "Découvrer si le vélo électrique est adapté à votre usage",
    alt: "An electric bike icon",
  },
  {
    image: images.savings,
    text: "Visualisez les économies réalisées par rapport à l’utilisation de votre véhicule",
    alt: "A saving icon",
  },
  {
    image: images.informations,
    text: "Trouvez les aides dont vous pouvez bénéficier pour l’achat de votre vélo électrique",
    alt: "An information icon",
  },
];

const Landing = () => {
  type IllustrationComponentProps = {
    imgSrc: string;
    imgAlt: string;
    children: ReactNode;
  };
  const IllustrationComponent = ({
    imgSrc,
    imgAlt,
    children,
  }: IllustrationComponentProps) => {
    return (
      <Box col gap="s" alignItems="center" flexRatio={1}>
        <Image src={imgSrc} alt={imgAlt} square="100px" />
        {children}
      </Box>
    );
  };

  const navigate = useNavigate();

  return (
    <Box col all="xxl" gap="xl">
      <Box as="span">
        <Heading size="h2" align="center">
          Être vous prêt à vous déplacer en vélo électrique ?
        </Heading>
        <Text align="center">
          Vos déplacements sont-ils adaptés au vélo électrique? Quelles
          économies pourriez-vous effectuer sur le long terme? Quelles sont les
          aides dont vous pourriez bénéficier pour son achat? Notre simulateur
          répond à toutes vos questions !
        </Text>
      </Box>
      <Box flex gap="m" justifyContent="space-between">
        {IllustrationsData.map(({ image, text, alt }) => {
          return (
            <IllustrationComponent imgSrc={image} imgAlt={alt}>
              <Text>{text}</Text>
            </IllustrationComponent>
          );
        })}
      </Box>
      <Box flex justifyContent="center">
        <Button color="primary" onClick={() => navigate("/simulator")}>
          Commencer ma simulation
        </Button>
      </Box>
    </Box>
  );
};

export default Landing;
