type Dimension = string | number;
type ImageProps = {
  width?: Dimension;
  height?: Dimension;
  square?: Dimension;
  src: string;
  alt: string;
};
const Image = ({ width, height, square, src, alt }: ImageProps) => {
  let dimensionProps: { width?: Dimension; height?: Dimension } = {};
  if (square) {
    dimensionProps = { width: square, height: square };
  } else {
    dimensionProps = { width, height };
  }
  return <img src={src} alt={alt} {...dimensionProps} />;
};

export default Image;
