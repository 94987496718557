import { useFormikContext } from "formik";
import { FormValues } from "../../Form";
import { TypePicker } from "../../../components/form/TypePicker";
import { Text } from "../../../components/typography/Text";
import SliderInput from "../../../components/form/SliderInput";
import SimulatorLayout from "./private/SimulatorLayout";
import { useNavigate } from "react-static-url-router";

const SubventionStepContent = () => {
  const navigate = useNavigate();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const shouldDisplayisTeacher = values.subvention.isOfficial === "YES";
  const shouldDisplayHasCompanySetUpSubvention =
    values.subvention.isOfficial === "NO";
  const shouldDisplayCompanySubventionFormat =
    shouldDisplayHasCompanySetUpSubvention &&
    values.subvention.hasCompanySetUpSubvention === "YES";
  const shouldSubventionAmountInput =
    shouldDisplayCompanySubventionFormat &&
    values.subvention.companySubventionFormat !== "unknown";

  const shouldDisableLink = () => {
    const isNotOfficial = values.subvention.isOfficial === "NO";
    const hasCompanySetUpSubvention =
      values.subvention.hasCompanySetUpSubvention === "YES";
    const companySubventionFormat =
      values.subvention.companySubventionFormat !== "unknown";
    const amount = values.subvention.amount > 0;
    const maxAmount = values.subvention.maxAmount > 0;
    const isTeacher = values.subvention.isTeacher !== "unknown";

    const shouldEnableLink: boolean =
      (isNotOfficial &&
        hasCompanySetUpSubvention &&
        companySubventionFormat &&
        amount &&
        maxAmount) ||
      (!isNotOfficial && isTeacher) ||
      (isNotOfficial && !hasCompanySetUpSubvention);

    return !shouldEnableLink;
  };

  const redirectToResultPage = () => {
    const stringifiedValues = JSON.stringify(values);
    const params = new URLSearchParams({ simulator_result: stringifiedValues });
    navigate(`/result?${params}`);
  };
  return (
    <SimulatorLayout
      isFirstStep={false}
      onPreviousClick={() => setFieldValue("vehicle.submitted", false)}
      isLastStep={true}
      isCtaDisabled={shouldDisableLink()}
      onCtaClick={redirectToResultPage}
    >
      <TypePicker.Root fieldName="subvention[isOfficial]">
        <TypePicker.Label as={Text}>
          Êtes-vous un agent de la fonction publique ?
        </TypePicker.Label>
        <TypePicker.OptionsGroup direction="row">
          <TypePicker.Option value="YES">Oui</TypePicker.Option>
          <TypePicker.Option value="NO">Non</TypePicker.Option>
        </TypePicker.OptionsGroup>
      </TypePicker.Root>
      {shouldDisplayisTeacher && (
        <TypePicker.Root fieldName="subvention[isTeacher]">
          <TypePicker.Label as={Text}>
            Êtes vous un(e) enseignant(e) du second cycle ?
          </TypePicker.Label>
          <TypePicker.OptionsGroup direction="row">
            <TypePicker.Option value="YES">Oui</TypePicker.Option>
            <TypePicker.Option value="NO">Non</TypePicker.Option>
          </TypePicker.OptionsGroup>
        </TypePicker.Root>
      )}
      {shouldDisplayHasCompanySetUpSubvention && (
        <TypePicker.Root fieldName="subvention[hasCompanySetUpSubvention]">
          <TypePicker.Label as={Text}>
            Votre entreprise a-t-elle mis en place le Forfait Mobilité Durable ?
          </TypePicker.Label>
          <TypePicker.OptionsGroup direction="row">
            <TypePicker.Option value="YES">Oui</TypePicker.Option>
            <TypePicker.Option value="NO">Non</TypePicker.Option>
          </TypePicker.OptionsGroup>
        </TypePicker.Root>
      )}
      {shouldDisplayCompanySubventionFormat && (
        <TypePicker.Root fieldName="subvention[companySubventionFormat]">
          <TypePicker.Label as={Text}>
            Quel est le format de l’indemnité mise en place au sein de votre
            entreprise ?
          </TypePicker.Label>
          <TypePicker.OptionsGroup direction="row">
            <TypePicker.Option value="Daily">Journalière</TypePicker.Option>
            <TypePicker.Option value="Mileage">Kilométrique</TypePicker.Option>
          </TypePicker.OptionsGroup>
        </TypePicker.Root>
      )}
      {shouldSubventionAmountInput && (
        <>
          <SliderInput.Root fieldName="subvention[amount]">
            <SliderInput.Label>
              Quel est le montant de l’indemnité{" "}
              {values.subvention.companySubventionFormat === "Daily"
                ? "journalière"
                : "kilométrique"}{" "}
              ?
            </SliderInput.Label>
            <SliderInput.Input
              max={1}
              step={0.01}
              value={values.subvention.amount}
              unit={
                values.subvention.companySubventionFormat === "Daily"
                  ? "€ / jour"
                  : "€ / km"
              }
            />
          </SliderInput.Root>
          <SliderInput.Root fieldName="subvention[maxAmount]">
            <SliderInput.Label>
              Quel est le montant maximal du Forfait Mobilité Durable par an ?
            </SliderInput.Label>
            <SliderInput.Input
              max={800}
              step={1}
              value={values.subvention.maxAmount}
              unit="€"
            />
          </SliderInput.Root>
        </>
      )}
    </SimulatorLayout>
  );
};

export { SubventionStepContent };
