import { ReactNode } from "react";
import "./Text.scss";

export type TextProps = {
  color?: "primary" | "secondary";
  size?: "s" | "m";
  variant?: "regular" | "medium" | "bold";
  align?: "start" | "center" | "end";
  children: ReactNode;
  className?: string | string[];
  as?: "p" | "label";
  htmlFor?: string;
};

const Text = ({
  as = "p",
  color = "primary",
  variant = "regular",
  size = "m",
  align,
  children,
  className,
  htmlFor,
}: TextProps) => {
  const Tag = as;
  const colorClass = `Text--color-${color}`;
  const sizeClass = `Text--size-${size}`;
  const variantClass = `Text--variant-${variant}`;
  const alignClass = `Text--align-${align}`;
  const additionalClasses =
    typeof className === "string" ? className : className?.join(" ");
  const textTagClass = [
    "Text",
    colorClass,
    sizeClass,
    variantClass,
    alignClass,
    additionalClasses,
  ]
    .filter((el) => el)
    .join(" ");
  return (
    <Tag className={textTagClass} {...{ htmlFor }}>
      {children}
    </Tag>
  );
};

export { Text };
