import React, { ReactNode } from "react";
import "./Heading.scss";

type HeadingProps = {
  size?: "h5" | "h4" | "h3" | "h2" | "h1";
  children: ReactNode;
  align?: "start" | "center" | "end";
  className?: string;
  variant?: "regular" | "primary";
};
const Heading = ({
  size = "h3",
  children,
  align,
  variant = "regular",
  className,
}: HeadingProps) => {
  const sizeClass = `Heading--size-${size}`;
  const alignClass = align ? `Heading--align-${align}` : undefined;
  const variantClass = variant ? `Heading--variant-${variant}` : undefined;
  const headingTagClass = [
    "Heading",
    sizeClass,
    alignClass,
    variantClass,
    className,
  ]
    .filter((el) => el)
    .join(" ");
  const Headingtag = size;
  return <Headingtag className={headingTagClass}>{children}</Headingtag>;
};

export default Heading;
