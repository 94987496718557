import { ReactNode } from "react";
import "./Box.scss";

type Spacings =
  | "xxxs"
  | "xxs"
  | "xs"
  | "s"
  | "m"
  | "l"
  | "xl"
  | "xxl"
  | "xxxl"
  | "no";

type SideSpacings = {
  inline_end?: Spacings;
  inline_start?: Spacings;
  block_end?: Spacings;
  block_start?: Spacings;
};

export type BoxProps = {
  as?: "div" | "span";
  flex?: boolean;
  col?: boolean;
  gap?: Spacings;
  justifyContent?: "center" | "start" | "end" | "space-between";
  alignItems?: "center" | "start" | "end";
  all?: Spacings;
  flexRatio?: 0 | 1 | 2;
  children: ReactNode;
  className?: string;
  hidden?: boolean;
  reverse?: boolean;
  border?: "primary" | "primary-light";
  padding?: SideSpacings | Spacings;
  rounded?: boolean;
};

const buildPaddingClass = (padding: SideSpacings | Spacings): string => {
  if (typeof padding === "string") {
    return `Box--padding-${padding}`;
  }
  const { inline_end, inline_start, block_end, block_start } = padding;
  const inlineEndClass = inline_end
    ? `Box--padding-inline-end-${inline_end}`
    : undefined;
  const inlineStartClass = inline_start
    ? `Box--padding-inline-start-${inline_start}`
    : undefined;
  const blockEndClass = block_end
    ? `Box--padding-block-end-${block_end}`
    : undefined;
  const blockStartClass = block_start
    ? `Box--padding-block-start-${block_start}`
    : undefined;
  return [inlineEndClass, inlineStartClass, blockEndClass, blockStartClass]
    .filter((el) => el)
    .join(" ");
};

const Box = ({
  as = "div",
  rounded,
  border,
  children,
  flex,
  col,
  gap,
  justifyContent,
  alignItems,
  all,
  flexRatio,
  className,
  hidden,
  reverse,
  padding,
}: BoxProps) => {
  const Container = as;
  const flexClass = flex || col ? `Box--flex` : undefined;
  const colClass = col ? `Box--col` : undefined;
  const gapClass = flexClass && gap ? `Box--gap-${gap}` : undefined;
  const allClass = all ? `Box--all-${all}` : undefined;
  const justifyContentClass =
    flexClass && justifyContent
      ? `Box--justifyContent-${justifyContent}`
      : undefined;
  const alignItemsClass =
    flexClass && alignItems ? `Box--alignItems-${alignItems}` : undefined;
  const hiddenClass = hidden ? `Box--hidden` : undefined;
  const flexRatioClass = flexRatio && `Box--flexRatio-${flexRatio}`;
  const reverseClass =
    flexClass && reverse
      ? `Box--flex${col ? "--col" : ""}--reverse`
      : undefined;
  const borderClass = border ? `Box--border-${border}` : undefined;
  const roundedClass = rounded ? `Box--rounded` : undefined;
  const paddingClass = padding ? buildPaddingClass(padding) : undefined;
  const containerClass = [
    "Box",
    flexClass,
    colClass,
    gapClass,
    allClass,
    justifyContentClass,
    flexRatioClass,
    alignItemsClass,
    className,
    hiddenClass,
    reverseClass,
    borderClass,
    roundedClass,
    paddingClass,
  ]
    .filter((el) => el)
    .join(" ");

  return <Container className={containerClass}>{children}</Container>;
};

export default Box;
